import React, { useRef, useState, useEffect } from "react";
import OrganicApple from "../best_selling/Organic_apple";
import Bread from "../best_selling/Bread";
import Coffee from "../best_selling/Coffe";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";

export default function BestSellingProduct() {
  const containerRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [isMobile, setIsMobile] = useState(false); // Track if the screen is mobile
  const totalPages = isMobile ? 6 : 3; // Total pages change for mobile (6 items)

  // Function to check if the screen is mobile
  const checkMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  const scroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth; // Scroll by container width
      const newPage = direction === "left" ? currentPage - 1 : currentPage + 1;

      if (newPage >= 1 && newPage <= totalPages) {
        containerRef.current.scrollBy({
          left: direction === "left" ? -scrollAmount : scrollAmount,
          behavior: "smooth",
        });
        setCurrentPage(newPage); // Update the page number
      }
    }
  };

  return (
    <div className="best_selling_product___">
      <div className="d-flex align-items-center py-3 our_product_head_div">
        <h1>Our Best-Selling Products</h1>
        <div className="flex-grow-1 throwline mx-2"></div>
        <button
          className="arrow-button"
          onClick={() => scroll("left")}
          aria-label="Scroll Left"
        >
          <FaAngleLeft />
        </button>
        <div className="d-flex justify-content-center align-items-center py-2 pagenum_div">
          <span>{currentPage}/{totalPages}</span>
        </div>
        <button
          className="arrow-button"
          onClick={() => scroll("right")}
          aria-label="Scroll Right"
        >
          <FaAngleRight />
        </button>
      </div>

      <div className="product-container" ref={containerRef}>
        {/* Render items based on the screen size */}
        {isMobile ? (
          <>
            <div className="product-item"><OrganicApple /></div>
            <div className="product-item"><Bread /></div>
            <div className="product-item"><Coffee /></div>
            <div className="product-item"><OrganicApple /></div>
            <div className="product-item"><Bread /></div>
            <div className="product-item"><Coffee /></div>
          </>
        ) : (
          <>
            <div className="product-item" style={{ gap: "15px" }}>
              <OrganicApple />
              <Bread />
              <Coffee />
            </div>
            <div className="product-item" style={{ gap: "15px" }}>
              <OrganicApple />
              <Bread />
              <Coffee />
            </div>
            <div className="product-item" style={{ gap: "15px" }}>
              <OrganicApple />
              <Bread />
              <Coffee />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
